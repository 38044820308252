// youtube iframe api
if (!(location.pathname === '/cn/' || location.pathname === '/cn/index.html')) {
	var tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	var player;
	var lang = location.pathname.split('/')[1];
	var videotitle = document.getElementById('js-idx-youtube-title_' + lang + '').innerHTML;
}
function onYouTubeIframeAPIReady() {
	document.getElementById('js-idx-youtube').innerHTML =
		'<div class="idx-videos-youtube-block"><img src="/images/index/videos_' + lang +
		'.jpg" class="width-u-100" data-view="pc tablet"><img src="/images/index/videos_' + lang +
		'_sp.jpg" class="width-u-100" data-view="sp"><h3 class="mod-head-block">' + videotitle + '</h3>' +
		'<a href="#" id="js-idx-youtube-btn" class="idx-videos-youtube-block-btn" tabindex="0">' +
		'<img src="/images/video_play_btn.png" class="width-u-100"></a>' +
		'<div class="mod-auto mod-auto-more mod-more mod-more-absolute"><a href="/' + lang +
		'/videos/" class="mod-a"><p class="mod-more-text">VIEW ALL VIDEOS</p></a></div></div>';

	var getVideoID = document.getElementById('js-idx-youtube').dataset.videoid;

	document.getElementById('js-idx-youtube-btn').addEventListener('click', function () {
		clickPlayBtn();
	});
	document.getElementById('js-idx-youtube-btn').addEventListener('keydown', function (e) {
		if (e.key === 'Enter') { clickPlayBtn(); }
	});

	function clickPlayBtn () {
		player = new YT.Player('js-idx-youtube', {
			height: '360',
			width: '640',
			videoId: getVideoID,
			playerVars: {
				'showinfo': 0,
				'rel': 0
			},
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
	}
}
function onPlayerReady(event) {
	event.target.playVideo();
}
function onPlayerStateChange(event) {
	// 現在のプレーヤーの状態を取得
	var ytStatus = event.data;
	// 再生終了したとき、iframeを削除
	if (ytStatus === YT.PlayerState.ENDED) {
		player.destroy();
	}
}

(function($, IBANEZ){
	const urlRegion = IBANEZ.va.pathname.split('/')[1];

	// メインのスライダー --------------------------------------------------------------------------
	var mainSlider = '#main-slide';
	var thumbnailItem = '.idx-main-nav-item';

	$(thumbnailItem).each(function(){
		var index = $(thumbnailItem).index(this);
		$(this).attr('data-index', index);
	});

	$(mainSlider).on('init', function(){
		var index = $('.slide-item.slick-slide.slick-current').attr('data-slick-index');
		$(thumbnailItem+'[data-index="'+index+'"]').addClass('thumbnail-current');
	});

	$(mainSlider).slick({
		accessibility: false,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 0,
		fade: false,
		arrows: false,
		dots: false,
		swipe: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		infinite: true
	});

	$(thumbnailItem).on('click', function() {
		var index = $(this).attr('data-index');
		$(mainSlider).slick('slickGoTo', index, false);
	});

	$(mainSlider).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$(thumbnailItem).each(function() {
			$(this).removeClass('thumbnail-current');
		});
		$(thumbnailItem+'[data-index="'+ nextSlide +'"]').addClass('thumbnail-current');
	});

	// 一時停止ボタン
	const toggleMainSlide = ($e) => {
		if (!$e.hasClass('is-stop')) {
			$(mainSlider).slick('slickPause');
			$e.addClass('is-stop');
			$e.text('PLAY');
		} else {
			$(mainSlider).slick('slickPlay');
			$e.removeClass('is-stop');
			$e.text('STOP');
		}
	};
	$('.idx-main-stop').on('click', (e) => { toggleMainSlide($(e.currentTarget)); });
	$('.idx-main-stop').keydown((e) => {
		if (e.key === 'Enter') { toggleMainSlide($(e.currentTarget)); }
	});

	// product部分 --------------------------------------------------------------------------
	// var $wrap = $('.idx-product-tabs-wrap');
	var $parentIn = $('.js-tab-parent-in');
	var $childIn = $('.js-tab-child-in');
	var $productSlider = $('.idx-product-slide');
	var $searchtext = $('.js-searchtext');
	var $searchBtn = $('.js-prodSearch');

	if($(window).width() > 767) {
		// PC, Tablet
		// 初期表示
		$parentIn.eq(0).addClass('is-active');
		$childIn.eq(0).addClass('is-active');
		$childIn.eq(0).find('a[tabindex="-1"]').each((idx, ele) => { $(ele).attr('tabindex', '0'); });
		$childIn.eq(0).children($productSlider).slick({
			dots: true,
			accessibility: false,
			autoplay: false,
			slidesToShow: 5,
			slidesToScroll: 5,
			infinite: false,
			prevArrow: '<i class="icon icon-arrow_left idx-product-slide-arrow_left"></i>',
			nextArrow: '<i class="icon icon-arrow_right idx-product-slide-arrow_right"></i>',
			responsive: [{
				breakpoint: 768,
				settings: {
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}]
		});

		$searchtext.html($parentIn.eq(0).find('span').html());
		$searchBtn.attr('href', `/${urlRegion}/products/search/${$parentIn.eq(0).attr('data-category')}/list/`);

		const tabClick = (_this) => {
			console.log('tabclickPC');
			var index = $parentIn.index(_this);
			$parentIn.removeClass('is-active');
			$childIn.removeClass('is-active');

			$productSlider.each(function (idx) {
				if ($productSlider.eq(idx).hasClass('slick-initialized')) {
					$productSlider.eq(idx).slick('unslick');
				}
			});
			$productSlider.find('a[tabindex="0"]').each((idx, ele) => { $(ele).attr('tabindex', '-1'); });

			$(_this).addClass('is-active');
			$childIn.eq(index).addClass('is-active');
			$childIn.eq(index).find('a[tabindex="-1"]').each((idx, ele) => { console.log(ele); $(ele).attr('tabindex', '0'); });
			$childIn.eq(index).children($productSlider).slick({
				dots: true,
				accessibility: false,
				autoplay: false,
				slidesToShow: 5,
				slidesToScroll: 5,
				infinite: false,
				prevArrow: '<i class="icon icon-arrow_left idx-product-slide-arrow_left"></i>',
				nextArrow: '<i class="icon icon-arrow_right idx-product-slide-arrow_right"></i>',
				responsive: [{
					breakpoint: 768,
					settings: {
						dots: false,
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}]
			});

			$searchtext.html($(_this).eq(0).find('span').html());
			$searchBtn.attr('href', `/${urlRegion}/products/search/${$(this).eq(0).attr('data-category')}/list/`);
		};
		$parentIn.each((idx, ele) => {
			$(ele).on('click', () => { tabClick(ele); });
			$(ele).keydown(function (e) {
				if (e.key === 'Enter') { tabClick(ele); }
			});
		});
		$('.js-prodSearch-input').change((e) => {
			let thisHref = $searchBtn.attr('href');
			$searchBtn.attr('href', `${thisHref}?cf_search_product_code=${$(e.currentTarget).val()}`);
		});
	} else {
		// SPでアコーディオン
		const tabClickSP = (_this) => {
			var index = $parentIn.index(_this);

			if (!$(_this).hasClass('is-active')) {
				$parentIn.removeClass('is-active');
				$childIn.removeClass('is-active');

				$productSlider.each(function (idx) {
					if ($productSlider.eq(idx).hasClass('slick-initialized')) {
						$productSlider.eq(idx).slick('unslick');
					}
				});
				$productSlider.find('a[tabindex="0"]').each((idx, ele) => { $(ele).attr('tabindex', '-1'); });

				$(_this).addClass('is-active');
				$childIn.eq(index).addClass('is-active');
				$childIn.eq(index).find('a[tabindex="-1"]').each((idx, ele) => { $(ele).attr('tabindex', '0'); });
				$childIn.eq(index).children($productSlider).slick({
					dots: true,
					accessibility: false,
					autoplay: false,
					slidesToShow: 5,
					slidesToScroll: 5,
					prevArrow: '<i class="icon icon-arrow_left idx-product-slide-arrow_left"></i>',
					nextArrow: '<i class="icon icon-arrow_right idx-product-slide-arrow_right"></i>',
					loop: true,
					infinite: true,
					responsive: [{
						breakpoint: 768,
						settings: {
							dots: false,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}]
				});

			} else {
				$parentIn.removeClass('is-active');
				$childIn.removeClass('is-active');

				$productSlider.each(function (idx) {
					if ($productSlider.eq(idx).hasClass('slick-initialized')) {
						$productSlider.eq(idx).slick('unslick');
					}
				});
			}
		};
		$parentIn.each((idx, ele) => {
			$(ele).on('click', () => { tabClickSP(ele); });
			$(ele).keydown(function (e) {
				if (e.key === 'Enter') { tabClickSP(ele); }
			});
		});
	}

	// NEWS スライダー -------------------------------------------------------------------
	$('.idx-news-slide').slick({
		accessibility: false,
		autoplay: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<i class="icon icon-arrow_left mod-slide-arrow mod-slide-arrow_left color_white"></i>',
		nextArrow: '<i class="icon icon-arrow_right mod-slide-arrow mod-slide-arrow_right color_white"></i>',
		loop: true,
		infinite: true,
		responsive: [{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			}
		}]
	});

	// facebook ウィジェット -------------------------------------------------------------
	// レスポンシブ対応（リサイズで再描画する）
	let first_width = $(window).width();
	let timer = false;
	$(window).resize(function () {
		if (timer !== false) {
			clearTimeout(timer);
		}
		timer = setTimeout(function () {
			let width = $(window).width();
			if (width != first_width) {
				fbiframe_reload();
				first_width = width;
			}
		}, 200);
	});
	function fbiframe_reload() {
		let width = Math.floor($('.idx-facebook').width());
		let height = Math.floor($('.idx-facebook').height());
		let fcAccount = (location.pathname === '/jp/' || location.pathname === '/jp/index.html') ? 'IbanezJapan' : 'ibanezguitars';
		let src = 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F' + fcAccount + '%2F&tabs=timeline&width=' +
			width + '&height=' + height + '&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId';
		$('.idx-facebook iframe').attr('src', '');
		$('.idx-facebook iframe').attr('src', src);
		$('.idx-facebook iframe').attr('width', width);
		$('.idx-facebook iframe').attr('height', height);
	}
	fbiframe_reload();

	// ページプラグインを追加する場合 -------------------------------------------------------
	// var facebookWrap = $('.idx-facebook');
	// var fbBeforeWidth = ''; // 前回変更したときの幅
	// var fbWidth = facebookWrap.width(); // 今回変更する幅
	// var fbTimer = false;
	// $(window).on('load resize', function() {
	// 	if(fbTimer !== false) {
	// 		clearTimeout(fbTimer);
	// 	}
	// 	fbTimer = setTimeout(function() {
	// 		fbWidth = Math.floor(facebookWrap.width()); // 変更後の幅を取得
	// 		// 前回の幅から変更があった場合のみ処理
	// 		// スマホだとスクロール時にリサイズが発生することがあるため
	// 		if (fbWidth !== fbBeforeWidth) {
	// 			facebookWrap.html(pagePluginCode(fbWidth)); // ページプラグインのコード変更
	// 			window.FB.XFBML.parse(); // ページプラグインの再読み込み
	// 			fbBeforeWidth = fbWidth; // 今回変更分を保存しておく
	// 		}
	// 	}, 200);
	// });

	// instagram --------------------------------------------------------------------------
	let instaJsonPath = '';
	if (location.pathname === '/jp/' || location.pathname === '/jp/index.html') {
		instaJsonPath = 'https://graph.facebook.com/v3.0/17841401526182015?fields=name%2Cmedia.limit(6)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%2Cmedia_type%2Cthumbnail_url%7D&access_token=EAAgdkwaZA640BABRwqxThxZBXK9y9XrQV7nhUyUJpkJQlm2e037AO6SUb6iygKZBP6dvZA3CLVuZCCVizyPZCjLLT3MmCZAiZCSMDhAuMocZBMFPZAO77NA8NKZAuSO0lHqYkbZAL3YWgRGr6qoxR73ZBu6XMqZB5B5bqdia1kZAdN1SRwgRgZDZD';
	} else {
		instaJsonPath = 'https://graph.facebook.com/v3.0/17841401432984001?fields=name%2Cmedia.limit(6)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%2Cmedia_type%2Cthumbnail_url%7D&access_token=EAAFxkl6iDnIBAGVJDdYUfaoDO3j7Fo3Wv6dogkk6oCDoCtxHO1MIp3wl57qJvD9gftV3L5vJ5fZAhiWy3DXAZA8BLLodkJU5LrwZBPw0FDCGq6WgPQVZA2WqUKf0xSYrxcmPwvwpAwEYZC6cJF0eelZCQXNdP9xaMoPL5WOdOfrAZCC5eyrImdJoF7aey5nD2sRDBgIrnE6uAZDZD';
	}
	let appendHTML = '';
	let instalength = 6;
	if ($(window).width() < 769) {
		instalength = 1;
	} else if ($(window).width() < 1025) {
		instalength = 4;
	} else {
		instalength = 6;
	}
	$.when(
		$.getJSON(instaJsonPath, (arr) => {
			for (let i = 0; i < instalength; i++) {
				if (!arr.media.data[i].thumbnail_url) {
					appendHTML += '<div class="pure-u-1-3 pure-u-lg-1-2 pure-u-md-1-1">'+
					`<a href="${arr.media.data[i].permalink}" target="_blank" class="idx-social-insta-a">`;
					appendHTML += `<img src="${arr.media.data[i].media_url}" class="width-u-100"></a></div>`;
				} else {
					appendHTML += '<div class="pure-u-1-3 pure-u-lg-1-2 pure-u-md-1-1">'+
					`<a href="${arr.media.data[i].permalink}" target="_blank" class="idx-social-insta-a">`;
					appendHTML += `<img src="${arr.media.data[i].thumbnail_url}" class="width-u-100"></a></div>`;
				}
			}
		})
	).done(() => {
		$('#js-idx-insta').append(appendHTML);
	});

})(window.jQuery, window.IBANEZ);
